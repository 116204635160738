import request from '@/utils/request'


// 查询轮播图列表
export function listBanner(query) {
  return request({
    url: '/driving-school/driving-school-banner/list',
    method: 'get',
    params: query
  })
}

// 查询轮播图分页
export function pageBanner(query) {
  return request({
    url: '/driving-school/driving-school-banner/page',
    method: 'get',
    params: query
  })
}
// 查询报名项目列表
export function getTraining(data) {
  return request({
    url: '/driving-school/driving-school-training/list',
    method: 'get',
    params: data
  })
}

// 查询轮播图详细
export function getBanner(data) {
  return request({
    url: '/driving-school/driving-school-banner/detail',
    method: 'get',
    params: data
  })
}

// 新增轮播图
export function addBanner(data) {
  return request({
    url: '/driving-school/driving-school-banner/add',
    method: 'post',
    data: data
  })
}

// 修改轮播图
export function updateBanner(data) {
  return request({
    url: '/driving-school/driving-school-banner/edit',
    method: 'post',
    data: data
  })
}

// 删除轮播图
export function delBanner(data) {
  return request({
    url: '/driving-school/driving-school-banner/delete',
    method: 'post',
    data: data
  })
}
